.wrap-mobile {
  color: #fff;
  text-align: left;
  list-style: none;
  margin: 0;
  padding: 0;
  display: none; }
  .wrap-mobile .lang {
    text-align: right; }
    .wrap-mobile .lang a {
      padding: 0.9em !important; }
  @media (max-width: 800px) {
    .wrap-mobile {
      display: block; } }
  .wrap-mobile .bm-burger-bars {
    background: #fff; }
  .wrap-mobile .logo {
    display: block;
    float: left;
    margin-top: 0.5em; }
    .wrap-mobile .logo a {
      display: block;
      margin: 0;
      padding: 0;
      color: #fff;
      font-weight: bold;
      font-size: 2em;
      font-family: 'Montserrat', sans-serif; }
      .wrap-mobile .logo a:hover {
        text-decoration: none; }
  .wrap-mobile .bm-burger-button {
    position: fixed;
    width: 25px;
    height: 20px;
    right: 36px;
    top: 18px; }
  .wrap-mobile .right .bm-burger-button {
    left: initial;
    right: 36px;
    cursor: pointer; }
  .wrap-mobile .bm-burger-bars {
    background: #fff !important;
    cursor: pointer; }
  .wrap-mobile .bm-morph-shape {
    fill: #fff; }
  .wrap-mobile .bm-menu {
    background: #fff; }
    .wrap-mobile .bm-menu a {
      color: #41566d;
      font-size: 1em;
      text-transform: capitalize;
      text-decoration: none;
      display: block;
      cursor: pointer;
      border-bottom: 1px solid #41566d; }
      .wrap-mobile .bm-menu a:hover, .wrap-mobile .bm-menu a:focus {
        color: #fff;
        background: #41566d; }
  .wrap-mobile .bm-overlay,
  .wrap-mobile .bm-menu-wrap {
    top: 0; }
  .wrap-mobile .bm-item-list a {
    padding: 0.8em; }
    .wrap-mobile .bm-item-list a span {
      margin-left: 10px;
      font-weight: 700; }
  .wrap-mobile .menu-1 .bm-cross {
    background: #bdc3c7; }
  .wrap-mobile .menu-1 .bm-menu {
    padding: 2.5em 1.5em 0;
    font-size: 1.15em; }
  .wrap-mobile .menu-2 .bm-cross {
    background: #999; }
  .wrap-mobile .menu-2 .bm-menu {
    padding: 3.4em 1em 0; }
  .wrap-mobile .menu-2 a {
    padding: 1em; }
  .wrap-mobile .menu-2 i {
    font-size: 1.7em;
    vertical-align: middle;
    color: #282a35; }
  .wrap-mobile .menu-3 .bm-cross {
    background: #888; }
  .wrap-mobile .menu-3 .bm-menu {
    padding: 2em 1em;
    font-size: 1.15em; }
  .wrap-mobile .menu-3 i {
    opacity: 0.5; }
  .wrap-mobile .menu-3 span {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.75em; }
  .wrap-mobile .menu-4 .bm-cross {
    background: #888; }
  .wrap-mobile .menu-4 h2 {
    margin: 0 auto;
    padding: 2em 1em;
    color: rgba(0, 0, 0, 0.4);
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2); }
    .wrap-mobile .menu-4 h2 i {
      margin-left: 0.2em; }
    .wrap-mobile .menu-4 h2 span {
      font-size: 1.6em;
      font-weight: 700; }
  .wrap-mobile .menu-4 a {
    padding: 1em;
    text-transform: uppercase;
    transition: background 0.3s, box-shadow 0.3s;
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2); }
    .wrap-mobile .menu-4 a span {
      letter-spacing: 1px;
      font-weight: 400; }
    .wrap-mobile .menu-4 a:hover, .wrap-mobile .menu-4 a:focus {
      background: rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
      color: #b8b7ad; }
